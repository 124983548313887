import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { isEmpty, isLength, isMatch } from '../../utils/Validate';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { userInfo } = useSelector(state => state.userLogin)
    const navigate = useNavigate()

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const resetPasswordFields = () => {
        setPassword("")
        setCurrentPassword("")
        setConfirmPassword("")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        // check fields
        if (isEmpty(password) || isEmpty(confirmPassword)) {
            setLoading(false)
            return toast('Please fill in all fields.', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        //check password length
        if (!isLength(password)) {
            setLoading(false)
            return toast('Password must be at least 8 characters.', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });

        }

        // password match
        if (!isMatch(password, confirmPassword)) {
            setLoading(false)
            return toast('Password did not match', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const response = await axios.post('/api/users/update-password', {
                currentPassword,
                password,
            }, config);



            if (response.data.success) {
                setLoading(false)
                resetPasswordFields()
                setTimeout(() => {
                    navigate('/');
                }, 3000);
                return toast('Password Updated Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            } else {
                setLoading(false)
                resetPasswordFields()
                return toast(response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        } catch (error) {
            setLoading(false)
            resetPasswordFields()
            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    return (
        <Grid container sx={{ marginTop: "30px" }}>
            <ToastContainer />
            <Grid xs={12} md={3}></Grid>
            <Grid md={6} xs={12}>
                <Card sx={{ p: 2 }}>
                    <Typography variant='h5' mt={2} textAlign="center">UPDATE YOUR PASSWORD</Typography>
                    <CardContent>
                        <form onSubmit={handleSubmit}>

                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    required
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    value={currentPassword}
                                    id="outlined-adornment-password"
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowCurrentPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm New Password</InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                    id="outlined-adornment-confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                />
                            </FormControl>

                            <div style={{ marginTop: "10px" }}>
                                <LoadingButton fullWidth className="button-primary" type="submit" variant="contained" loading={loading}>
                                    Update Password
                                </LoadingButton>
                            </div>

                        </form>
                    </CardContent>

                </Card>
            </Grid>
            <Grid xs={12} md={3}></Grid>
        </Grid>
    )
}

export default UpdatePassword