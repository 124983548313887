import { Box, Grid, Typography, CircularProgress } from "@mui/material"
import Card from "../../Card"
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SimilarJob from "../../job/SimilarJob";
import LineChartDataset from "./LineChartDataSet";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computeApplicationsStats, computeSuccessRate } from "../../../utils/Validate";

import { getUserDetails, setItem } from "../../../actions/userActions";
import { listPublishedJobs } from "../../../actions/jobActions"

const Dashboard = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.userLogin);
    const { user } = useSelector((state) => state.userDetails);
    const { jobs, loading } = useSelector((state) => state.publishedJobList);

    useState(() => {
        if (!userInfo) {
            navigate("/login")
        } else if (!user || !user.firstname) {
            dispatch(getUserDetails('profile'));

        }
        dispatch(listPublishedJobs())

    }, [userInfo, navigate, dispatch, user])

    return (
        <Box>
            <Grid container>
                <Grid xs={12} sm={6} md={4}
                    onClick={() => {
                        dispatch(setItem("applications"))
                        navigate("/applications")
                    }}
                    sx={{ cursor: "pointer" }}
                >
                    <Card
                        color="#167dc1"
                        metricName="Jobs Applied"
                        metricNumber={user?.appliedJobs?.length}
                        Icon={WorkOutlineIcon} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Card color="#ebbd1a"
                        metricName="Profile Views"
                        metricNumber={user?.profileViews}
                        Icon={PersonOutlineOutlinedIcon} />
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Card
                        color="#18bebe"
                        metricName="Success Rate"
                        metricNumber={computeSuccessRate(user)}
                        suffix="%"
                        Icon={CheckBoxOutlinedIcon}
                    />
                </Grid>

            </Grid>
            <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
                Application Statistics
            </Typography>
            <Grid container>

                <Grid xs={12}>
                    <LineChartDataset dataset={computeApplicationsStats(user)} />
                </Grid>




            </Grid>
            <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>Recommended Jobs</Typography>

            <Grid container >

                {loading ? <Typography textAlign="center"><CircularProgress /></Typography> : jobs?.filter(job => job?.title?.toLowerCase().includes(user?.preferredTitle?.toLowerCase())).slice(0, 4).map(job => (
                    <Grid xs={3}><SimilarJob {...job} /></Grid>
                ))}



            </Grid>







        </Box>
    )
}

export default Dashboard