

// check empty fields
export const isEmpty = (value) => {
    if (!value) return true;
    return false;
};

// check email format
export const isEmail = (email) => {
    // eslint-disable-next-line
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

// check password length
export const isLength = (password) => {
    if (password.length >= 8) return true;
    return false;
};

// check password match
export const isMatch = (password, cf_password) => {
    if (password === cf_password) return true;
    return false;
};

export function sanitizeInput(input) {
    // Use a regular expression to replace any character that is not a letter (a-z, A-Z) or space with an empty string
    return input?.replace(/[^a-zA-Z\s]/g, '');
}

export function acceptOnlyNumbers(input) {
    // Use a regular expression to replace any character that is not a number
    return input?.replace(/\D/g, '');
}


export const copyToClipboard = async (url) => {
    try {
        await navigator.clipboard.writeText(url);

    } catch (err) {
        console.error("Failed to copy: ", err);
    }
};

export function isStringInArrayOfObjects(str, arrayOfObjects) {
    // Check if arrayOfObjects is an array and not empty
    if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
        return false;
    }

    // Iterate through the array of objects
    for (let obj of arrayOfObjects) {
        // Check if the current object has a property that is a string and if that property matches the given string
        if (typeof obj === 'object' && obj !== null) {
            for (let key in obj) {
                if (typeof obj[key] === 'string' && obj[key] === str) {
                    return true;
                }
            }
        }
    }

    // If no match is found, return false
    return false;
}

export function limitString(str, limit) {
    return str.length > limit ? str.slice(0, limit) + "..." : str;
}

export const isProfileComplete = (user) => {
    if (user.firstname && user.lastname && user.avatar && user.company && user.proof && user.overview) {
        return true
    }
}

export function computeApplicantsOfferRatio(jobs) {
    if (!jobs || jobs.length === 0) return 0;

    const totalOffers = jobs.reduce((total, job) => {
        return total + job.offer.length;
    }, 0);

    if (totalOffers === 0) return 0

    const totalApplicants = jobs.reduce((total, job) => {
        return total + job.applicants.length;
    }, 0);

    const conversionRate = Math.round(totalApplicants / totalOffers)

    return conversionRate
}

export function computeAverageTimeToFill(jobs) {
    if (!jobs || jobs.length === 0) return 0;

    // Parse the createdAt date of the first job
    const firstJobCreatedAt = new Date(jobs[0].createdAt).getTime();

    // Get the current time in milliseconds
    const currentTime = new Date().getTime();

    // Calculate the difference in milliseconds
    const timeDifference = currentTime - firstJobCreatedAt;

    const totalOffers = jobs.reduce((total, job) => {
        return total + job.offer.length;
    }, 0);

    // Check if totalOffers is zero to avoid division by zero
    if (totalOffers === 0) return 0;

    // Calculate the average time in milliseconds
    const timeInDays = timeDifference / (24 * 60 * 60 * 1000);

    // Calculate the average time to fill in days
    const averageTimeToFill = Math.round(timeInDays / totalOffers);

    return averageTimeToFill;
}

export const computeSuccessRate = (user) => {
    if (!user.shortlisted || !user.initialInterview || !user.finalInterview || !user.accepted) return 0
    const shortlisted = user.shortlistedJobs.length
    const initialInterview = user.initialInterview.length
    const finalInterview = user.finalInterview.length
    const accepted = user.accepted.length
    const totalApplied = user.appliedJobs.length

    if (totalApplied === 0) return 0;

    const successRate = Math.round((shortlisted + initialInterview + finalInterview + accepted) / totalApplied)

    return successRate
}

export function viewFile(filename) {
    const filePath = `/uploads/${filename}`;  // Path relative to the public folder
    window.open(filePath, '_blank');
    console.log(filename);
    // Open in a new tab
}

export const viewResume = () => {
    window.open('/api/view/resume', '_blank');
};



export const downloadFile = (url) => {
    const filename = url.split('/').pop();
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', filename);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();

    //console.log(url.slice(15));
};

export function computeApplicationsStats(user) {
    // Initialize an array to hold the statistics for each month
    const monthlyStats = [];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Helper function to get the month index from a date
    function getMonthIndex(dateString) {
        const date = new Date(dateString);
        return date.getMonth(); // Returns month index (0 for Jan, 1 for Feb, etc.)
    }

    // Initialize counters for each month
    const counters = months.map(() => ({
        pending: 0,
        accepted: 0,
        rejected: 0,
    }));

    console.log(user)
    // Process pending applications
    user.appliedJobs?.forEach(job => {
        const monthIndex = getMonthIndex(job.appliedAt);
        counters[monthIndex].pending++;
    });

    // Process accepted offers

    if (Array.isArray(user.accepted)) {
        user.accepted.forEach(job => {
            if (job && job.acceptedAt) { // Validate job before accessing its properties
                const monthIndex = getMonthIndex(job.acceptedAt);
                counters[monthIndex].accepted++;
            } else {
                console.warn("Skipping invalid job entry:", job);
            }
        });
    }


    // Process rejected applications
    if (Array.isArray(user?.rejected)) {
        user.rejected.forEach((job, index) => {
            if (job && job.rejectedAt) { // Ensure valid entry
                const monthIndex = getMonthIndex(job.rejectedAt);
                counters[monthIndex].rejected++;
            } else {
                console.warn(`Skipping invalid rejected entry at index ${index}:`, job);
            }
        });
    } else {
        console.error("'rejected' is not an array or undefined:", user?.rejected);
    }
    // Create the monthly statistics objects
    months.forEach((month, index) => {
        monthlyStats.push({

            pending: counters[index].pending,
            accepted: counters[index].accepted,
            rejected: counters[index].rejected,
            month: month,
        });
    });

    return monthlyStats;
}

export function refreshPage() {
    window.location.reload();
}



export function computeApplicantsStats(user) {
    // Initialize an array to hold the statistics for each month
    const monthlyStats = [];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Helper function to get the month index from a date
    function getMonthIndex(dateString) {
        const date = new Date(dateString);
        return date.getMonth(); // Returns month index (0 for Jan, 1 for Feb, etc.)
    }

    // Initialize counters for each month
    const counters = months.map(() => ({
        shortlisted: 0,
        interviewed: 0,
        offered: 0,
    }));



    // Process pending applications
    if (Array.isArray(user?.shortlisted)) {
        user?.shortlisted?.forEach(usr => {
            if (usr && usr.shortlistedAt) {
                const monthIndex = getMonthIndex(usr.shortlistedAt);
                counters[monthIndex].shortlisted++;
            } else {
                console.warn("Skipping invalid user entry:", usr);
            }
        });

    }

    if (Array.isArray(user?.interviewed)) {
        user.interviewed.forEach((usr, index) => {
            if (usr && usr.interviewedAt) { // Ensure valid entry
                const monthIndex = getMonthIndex(usr.interviewedAt);
                counters[monthIndex].interviewed++;
            } else {
                console.warn(`Skipping invalid interviewed entry at index ${index}:`, usr);
            }
        });
    } else {
        console.error("'interviewed' is not an array or undefined:", user?.interviewed);
    }

    // Process offered users
    if (Array.isArray(user?.offered)) {
        user.offered.forEach((usr, index) => {
            if (usr && usr.offeredAt) { // Ensure valid entry
                const monthIndex = getMonthIndex(usr.offeredAt);
                counters[monthIndex].offered++;
            } else {
                console.warn(`Skipping invalid offered entry at index ${index}:`, usr);
            }
        });
    } else {
        console.error("'offered' is not an array or undefined:", user?.offered);
    }

    // Create the monthly statistics objects
    months.forEach((month, index) => {
        monthlyStats.push({

            shortlisted: counters[index].shortlisted,
            interviewed: counters[index].interviewed,
            offered: counters[index].offered,
            month: month,
        });
    });

    return monthlyStats;
}




export function computeMonthlyStats(user) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Initialize counters for each month from the current month to December
    const monthlyData = Array.from({ length: 12 }, (_, i) => ({
        month: (currentMonth + i) % 12,
        year: currentYear + Math.floor((currentMonth + i) / 12),
        pending: 0,
        accepted: 0,
        rejected: 0
    }));

    // Helper function to get the month and year of a job's date
    const getMonthYear = dateStr => {
        const date = new Date(dateStr);
        return { month: date.getMonth(), year: date.getFullYear() };
    };

    if (!user) return;

    // Count pending applications
    user.appliedJobs?.forEach(job => {
        const { month, year } = getMonthYear(job.date);
        if (year === currentYear && month >= currentMonth) {
            monthlyData[month - currentMonth].pending++;
        }
    });

    // Count accepted applications
    user.accepted?.forEach(job => {
        const { month, year } = getMonthYear(job.date);
        if (year === currentYear && month >= currentMonth) {
            monthlyData[month - currentMonth].accepted++;
        }
    });

    // Count rejected applications
    user.rejected?.forEach(job => {
        const { month, year } = getMonthYear(job.date);
        if (year === currentYear && month >= currentMonth) {
            monthlyData[month - currentMonth].rejected++;
        }
    });

    return monthlyData;
}

export function calculateProfileCompleteness(profile) {
    let completeness = 0;


    if (!profile) return;

    if (profile?.firstname) completeness += 5;
    if (profile?.lastname) completeness += 5;
    if (profile?.email) completeness += 5;
    if (profile?.preferredTitle) completeness += 5;
    if (profile?.phone) completeness += 5;
    if (profile?.stateOfResidence) completeness += 5;

    if (Array.isArray(profile?.skills) && profile?.skills.length > 0) completeness += 10;
    if (Array.isArray(profile?.education) && profile?.education.length > 0) completeness += 30;
    if (Array.isArray(profile?.experience) && profile?.experience.length > 0) completeness += 30;

    return completeness;
}


export const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Abuja",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara"
];

export const jobTitles = [
    "Other",
    "Account Executive",
    "Account Manager",
    "Accountant",
    "Accounts Assistant",
    "Accounts Payable Clerk",
    "Accounts Receivable Clerk",
    "Actuary",
    "Administrative Assistant",
    "Administrator",
    "Advertising Account Executive",
    "Advertising Manager",
    "Aerospace Engineer",
    "Air Traffic Controller",
    "Aircraft Mechanic",
    "Aircraft Technician",
    "Animator",
    "Anthropologist",
    "App Developer",
    "Applications Engineer",
    "Archaeologist",
    "Architect",
    "Archivist",
    "Art Director",
    "Art Historian",
    "Art Teacher",
    "Artist",
    "Assembler",
    "Assistant Manager",
    "Astronomer",
    "Athletic Trainer",
    "Attorney",
    "Audio Engineer",
    "Audit Manager",
    "Auditor",
    "Author",
    "Auto Mechanic",
    "Baker",
    "Bank Teller",
    "Banker",
    "Bar Manager",
    "Barber",
    "Bartender",
    "Beautician",
    "Behavioral Therapist",
    "Bench Technician",
    "Biochemist",
    "Biologist",
    "Biomedical Engineer",
    "Biomedical Scientist",
    "Bookkeeper",
    "Brand Manager",
    "Budget Analyst",
    "Building Inspector",
    "Bus Driver",
    "Business Analyst",
    "Business Consultant",
    "Business Development Manager",
    "Business Manager",
    "Buyer",
    "C++ Developer",
    "Cafeteria Worker",
    "Call Center Representative",
    "Camera Operator",
    "Car Salesman",
    "Cardiologist",
    "Caregiver",
    "Carpenter",
    "Case Manager",
    "Cashier",
    "Chef",
    "Chemical Engineer",
    "Chemist",
    "Chief Executive Officer (CEO)",
    "Chief Financial Officer (CFO)",
    "Chief Information Officer (CIO)",
    "Chief Operating Officer (COO)",
    "Child Care Worker",
    "Child Psychologist",
    "Chiropractor",
    "Civil Engineer",
    "Claims Adjuster",
    "Cleaner",
    "Clerk",
    "Clinical Psychologist",
    "Coach",
    "Compliance Officer",
    "Computer Programmer",
    "Computer Scientist",
    "Computer Technician",
    "Construction Manager",
    "Consultant",
    "Content Creator",
    "Content Writer",
    "Controller",
    "Cook",
    "Copy Editor",
    "Copywriter",
    "Corporate Trainer",
    "Corrections Officer",
    "Counselor",
    "Court Reporter",
    "Crane Operator",
    "Creative Director",
    "Credit Analyst",
    "Criminal Investigator",
    "Criminal Lawyer",
    "Crisis Counselor",
    "Customer Service Manager",
    "Customer Service Representative",
    "Cyber Security Analyst",
    "Data Analyst",
    "Data Entry Clerk",
    "Data Scientist",
    "Database Administrator",
    "Delivery Driver",
    "Dental Assistant",
    "Dental Hygienist",
    "Dentist",
    "Derrick Operator",
    "Designer",
    "Desktop Support Specialist",
    "Developer",
    "Dietitian",
    "Director",
    "Dispatcher",
    "Drafter",
    "Driver",
    "Editor",
    "Educator",
    "Electrical Engineer",
    "Electrician",
    "Electronics Engineer",
    "Elementary School Teacher",
    "EMT",
    "Engineer",
    "Engineering Manager",
    "Environmental Engineer",
    "Environmental Scientist",
    "Estimator",
    "Event Planner",
    "Executive Assistant",
    "Executive Director",
    "Facilities Manager",
    "Factory Worker",
    "Family Therapist",
    "Farmer",
    "Fashion Designer",
    "Film Director",
    "Finance Manager",
    "Financial Advisor",
    "Financial Analyst",
    "Financial Planner",
    "Firefighter",
    "Fitness Instructor",
    "Flight Attendant",
    "Florist",
    "Food Scientist",
    "Food Service Manager",
    "Forensic Scientist",
    "Forklift Operator",
    "Front Desk Clerk",
    "Front End Developer",
    "Full Stack Developer",
    "Fundraiser",
    "Gardener",
    "Geneticist",
    "Geologist",
    "Graphic Designer",
    "Groundskeeper",
    "Hairdresser",
    "Hardware Engineer",
    "Health and Safety Officer",
    "Healthcare Assistant",
    "Health Information Technician",
    "Health Services Manager",
    "Historian",
    "Home Health Aide",
    "Horticulturist",
    "Hospital Administrator",
    "Hotel Manager",
    "Housekeeper",
    "HR Assistant",
    "HR Manager",
    "Human Resources Specialist",
    "HVAC Technician",
    "Illustrator",
    "Industrial Designer",
    "Industrial Engineer",
    "Information Security Analyst",
    "Information Systems Manager",
    "Inspector",
    "Instructional Designer",
    "Insurance Agent",
    "Interior Designer",
    "Interpreter",
    "Investigator",
    "Investment Banker",
    "IT Consultant",
    "IT Manager",
    "IT Support Specialist",
    "Janitor",
    "Jeweler",
    "Journalist",
    "Judge",
    "Laboratory Technician",
    "Land Surveyor",
    "Landscaper",
    "Lawyer",
    "Legal Assistant",
    "Librarian",
    "Library Assistant",
    "Licensed Practical Nurse (LPN)",
    "Life Coach",
    "Loan Officer",
    "Logistics Coordinator",
    "Machine Operator",
    "Machinist",
    "Maintenance Worker",
    "Makeup Artist",
    "Management Consultant",
    "Manager",
    "Manufacturing Engineer",
    "Market Research Analyst",
    "Marketing Assistant",
    "Marketing Consultant",
    "Marketing Director",
    "Marketing Manager",
    "Massage Therapist",
    "Mechanical Engineer",
    "Mechanic",
    "Media Planner",
    "Medical Assistant",
    "Medical Billing Specialist",
    "Medical Laboratory Technician",
    "Medical Office Assistant",
    "Medical Receptionist",
    "Medical Records Clerk",
    "Medical Secretary",
    "Medical Technologist",
    "Mental Health Counselor",
    "Midwife",
    "Mobile Developer",
    "Mortgage Advisor",
    "Motorcycle Mechanic",
    "Music Producer",
    "Music Teacher",
    "Musician",
    "Nanny",
    "Network Administrator",
    "Network Engineer",
    "Nurse",
    "Nurse Practitioner",
    "Nutritionist",
    "Occupational Therapist",
    "Office Administrator",
    "Office Assistant",
    "Office Manager",
    "Operations Manager",
    "Optician",
    "Optometrist",
    "Orthodontist",
    "Painter",
    "Paralegal",
    "Paramedic",
    "Park Ranger",
    "Parts Manager",
    "Pastry Chef",
    "Patent Attorney",
    "Pathologist",
    "Payroll Clerk",
    "Payroll Specialist",
    "Personal Assistant",
    "Personal Trainer",
    "Pet Groomer",
    "Pharmacist",
    "Pharmacy Technician",
    "Photographer",
    "Physical Therapist",
    "Physician",
    "Physician Assistant",
    "Physicist",
    "Pilot",
    "Plumber",
    "Police Officer",
    "Politician",
    "Postal Worker",
    "PR Manager",
    "Product Manager",
    "Production Assistant",
    "Production Manager",
    "Professor",
    "Project Coordinator",
    "Project Manager",
    "Property Manager",
    "Psychiatrist",
    "Psychologist",
    "Public Relations Specialist",
    "Purchasing Manager",
    "Quality Assurance Manager",
    "Quantity Surveyor",
    "Radiologic Technologist",
    "Real Estate Agent",
    "Receptionist",
    "Recruiter",
    "Research Analyst",
    "Research Assistant",
    "Research Scientist",
    "Restaurant Manager",
    "Retail Manager",
    "Risk Manager",
    "Sales Assistant",
    "Sales Associate",
    "Sales Director",
    "Sales Executive",
    "Sales Manager",
    "Sales Representative",
    "Sanitation Worker",
    "Scientist",
    "Screenwriter",
    "Secretary",
    "Security Guard",
    "Security Specialist",
    "Senior Accountant",
    "Senior Developer",
    "Server",
    "Service Technician",
    "Social Media Manager",
    "Social Worker",
    "Software Developer",
    "Software Engineer",
    "Software Tester",
    "Solution Architect",
    "Sound Engineer",
    "Speech Therapist",
    "Statistician",
    "Store Manager",
    "Structural Engineer",
    "Student",
    "Substance Abuse Counselor",
    "Surgeon",
    "Surveyor",
    "Systems Administrator",
    "Systems Analyst",
    "Systems Engineer",
    "Tailor",
    "Tax Advisor",
    "Tax Preparer",
    "Teacher",
    "Technical Support Specialist",
    "Technical Writer",
    "Technician",
    "Telemarketer",
    "Test Engineer",
    "Therapist",
    "Tour Guide",
    "Trader",
    "Train Conductor",
    "Training Manager",
    "Translator",
    "Travel Agent",
    "Truck Driver",
    "Tutor",
    "UI Designer",
    "UX Designer",
    "Veterinarian",
    "Video Editor",
    "Virtual Assistant",
    "Voice Actor",
    "Waiter",
    "Warehouse Manager",
    "Warehouse Worker",
    "Web Designer",
    "Web Developer",
    "Welder",
    "Writer",
    "Zoologist"
];

export const industries = [
    "Other",
    "Aerospace",
    "Agriculture",
    "Apparel",
    "Automotive",
    "Banking",
    "Biotechnology",
    "Chemicals",
    "Construction",
    "Consulting",
    "Consumer Goods",
    "Computer",
    "Education",
    "Electronics",
    "Energy",
    "Entertainment",
    "Environmental",
    "Finance",
    "Food & Beverage",
    "Government",
    "Healthcare",
    "Hospitality",
    "Information Technology",
    "Insurance",
    "Legal",
    "Logistics",
    "Manufacturing",
    "Marketing",
    "Media",
    "Mining",
    "Non-Profit",
    "Pharmaceuticals",
    "Real Estate",
    "Retail",
    "Software",
    "Telecommunications",
    "Transportation",
    "Utilities",
    "Waste Management",
    "Wholesale"
];

export const professionalSkills = [
    "Accountability",
    "Adaptability",
    "Advertising",
    "Analytical Skills",
    "Android Development",
    "Attention to Detail",
    "Audit Management",
    "AutoCAD",
    "Back-End Development",
    "Brand Management",
    "Budget Management",
    "Business Analysis",
    "Business Development",
    "Business Intelligence",
    "C++",
    "C#",
    "Cloud Computing",
    "Collaboration",
    "Communication",
    "Compliance",
    "Conflict Resolution",
    "Content Management",
    "Contract Management",
    "Copywriting",
    "Creativity",
    "CRM Software",
    "Critical Thinking",
    "Cross-functional Team Leadership",
    "Customer Relationship Management",
    "Customer Service",
    "Data Analysis",
    "Data Mining",
    "Data Science",
    "Database Management",
    "Debugging",
    "Decision Making",
    "Design Thinking",
    "Digital Marketing",
    "Documentation",
    "E-commerce",
    "Editing",
    "Electrical Engineering",
    "Electronics",
    "Email Marketing",
    "Embedded Systems",
    "Emotional Intelligence",
    "Event Planning",
    "Excel",
    "Executive Management",
    "Financial Analysis",
    "Financial Planning",
    "Forecasting",
    "Front-End Development",
    "Graphic Design",
    "HTML/CSS",
    "Human Resources",
    "iOS Development",
    "IT Management",
    "Java",
    "JavaScript",
    "Leadership",
    "Lean Manufacturing",
    "Machine Learning",
    "Market Analysis",
    "Marketing Strategy",
    "Mechanical Engineering",
    "Microsoft Office",
    "Mobile Development",
    "Negotiation",
    "Network Security",
    "Network Troubleshooting",
    "Node.js",
    "Operations Management",
    "Organizational Skills",
    "Performance Management",
    "PHP",
    "Planning",
    "Problem Solving",
    "Product Development",
    "Product Management",
    "Project Management",
    "Public Relations",
    "Python",
    "Quality Assurance",
    "React.js",
    "Research",
    "Risk Management",
    "Ruby on Rails",
    "Sales",
    "Sales Management",
    "SCRUM",
    "SEO",
    "Social Media Management",
    "Software Development",
    "Software Testing",
    "Solution Selling",
    "SQL",
    "Strategic Planning",
    "Team Leadership",
    "Technical Support",
    "Time Management",
    "Training",
    "UI/UX Design",
    "User Research",
    "Video Production",
    "Web Development",
    "Windows Administration",
    "WordPress",
    "Writing"
];


export const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the",
    "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor",
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
    "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq",
    "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North",
    "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
    "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
    "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia",
    "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
    "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];



