
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Autocomplete, Chip, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, resetSeekerCreateForm, setItem, updateSeekerForm, updateUserProfile, userProfileUpdateReset } from '../../../actions/userActions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import EducationItem from './EducationItem';
import EducationForm from './EducationForm';
import ExperienceItem from './ExperienceItem';
import ExperienceForm from './ExperienceForm';
import { jobTitles, professionalSkills, states } from '../../../utils/Validate';




const steps = ['Biodata', 'Education', 'Work Experience', 'Skills'];

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };





// function getStyles(skill, userSkill, theme) {
//     return {
//         fontWeight:
//             userSkill.indexOf(skill) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }


export default function HorizontalLinearStepper(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { userInfo } = useSelector((state) => state.userLogin);

    const { user } = useSelector((state) => state.userDetails);


    const { formData } = useSelector(state => state.seekerForm);
    const { isEditing } = useSelector(state => state.editingState)
    const [loading, setLoading] = useState(false)


    const [stateOfResidence, setStateOfResidence] = useState(user ? user.stateOfResidence : formData ? formData.stateOfResidence : "")

    const [firstname, setFirstname] = useState(user ? user.firstname : formData ? formData.firstname : '')
    const [lastname, setLastname] = useState(user ? user.lastname : formData ? formData.lastname : '')
    const [phone, setPhone] = useState(user ? user.phone : formData ? formData.firstname : 0)
    const [preferredTitle, setPreferredTitle] = useState(user ? user.preferredTitle : formData ? formData.preferredTitle : '')

    const [userSkill, setUserSkill] = useState(user?.skills || formData?.skills || []);

    const [education, setEducation] = useState(user?.education || formData?.education || []);

    const [experience, setExperience] = useState(user.experience || formData?.experience || [])



    const [searchParams] = useSearchParams()

    const activestep = Number(searchParams.get("activestep"))


    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setUserSkill(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    const handleChange = (event, newValue) => {
        setUserSkill(newValue);
    };



    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {

            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleSubmit = () => {

        setLoading(true)

        dispatch(updateUserProfile(formData))

        setLoading(false)

        if (!loading) {

            dispatch(getUserDetails("profile"))
            dispatch(setItem("profile"))
            navigate("/profile")
            dispatch(resetSeekerCreateForm())
            dispatch(userProfileUpdateReset());
        }


    }
    const handleStepOne = (e) => {
        e.preventDefault()
        dispatch(updateSeekerForm({ firstname, lastname, phone, preferredTitle, stateOfResidence }))
        handleNext()

    }
    const handleStepTwo = (e) => {
        e.preventDefault()

        dispatch(updateSeekerForm({ education }))
        handleNext()
    }

    const handleStepThree = (e) => {
        e.preventDefault()
        dispatch(updateSeekerForm({ experience }))
        handleNext()
    }


    const handleEditEducation = (id, updatedValues) => {
        setEducation(
            education.map((item, i) => (item._id === id ? { ...item, ...updatedValues } : item))
        );

    };

    const handleAddEducation = (newEducation) => {
        setEducation([...education, newEducation]);
        // Call the provided function to handle submission
    };

    const handleAddSkill = () => {
        dispatch(updateSeekerForm({ skills: userSkill }))
    };

    const getEducationDetails = (index) => {
        return education[index] || {};
    };

    const handleEditExperience = (index, updatedValues) => {
        setExperience(
            experience.map((item, i) => (i === index ? { ...item, ...updatedValues } : item))
        );

    };

    const handleAddExperience = (newExperience) => {
        setExperience([...experience, newExperience]);
        // Call the provided function to handle submission
    };

    const getExperienceDetails = (index) => {
        return experience[index] || {};
    };





    useEffect(() => {

        if (!userInfo) {
            navigate("/login")
        } else if (!user || !user.firstname) {
            dispatch(getUserDetails('profile'));

        }
        const handleStepChange = () => setActiveStep(activestep)
        handleStepChange()
    }, [activestep, navigate, dispatch, userInfo, user])

    return (
        <Box sx={{ width: '100%', mt: 3, }}>
            <Stepper activeStep={activeStep} sx={{ mb: 5, }}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}
                                sx={{
                                    '& .MuiStepLabel-label': {
                                        fontSize: isSmallScreen && '0.61rem', // Reduce font size
                                        whiteSpace: isSmallScreen && 'nowrap', // Prevent labels from wrapping
                                    },
                                    '& .MuiStepLabel-alternativeLabel': {
                                        marginTop: isSmallScreen && '8px', // Adjust vertical spacing
                                    },
                                }}

                            >{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Congratulations! - You have completed your Profile
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </Fragment>
            ) : (
                <Fragment>

                    {activeStep === 0 && (
                        <Box>


                            <TextField
                                margin="normal"

                                fullWidth
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}

                                label="First Name"
                                sx={{ mb: 2 }}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2 }}
                                label="Last name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}

                                autoComplete="Last name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                type="number"
                                onChange={(e) => setPhone(e.target.value)}

                                label="Phone Number"
                                value={Number(phone)}
                                sx={{ mb: 2 }}
                                autoComplete="Phone Number"
                                autoFocus
                            />
                            <FormControl fullWidth sx={{ my: 3 }}>
                                <InputLabel id="preferred-title">Preferred Title</InputLabel>
                                <Select
                                    labelId="preferred-title"
                                    id="select-preferred-title"
                                    value={preferredTitle}
                                    label="Preferred Title"
                                    required
                                    onChange={(e) => setPreferredTitle(e.target.value)}
                                >

                                    {jobTitles.map(title => (
                                        <MenuItem value={title} key={title}>{title}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ my: 3 }}>
                                <InputLabel id="state-residence">State of Residence</InputLabel>
                                <Select
                                    labelId="state-residence"
                                    id="select-state-residence"
                                    value={stateOfResidence}
                                    label="State Of Residence"
                                    required
                                    onChange={(e) => setStateOfResidence(e.target.value)}
                                >

                                    {states.map(state => (
                                        <MenuItem value={state} key={state}>{state}</MenuItem>
                                    ))}


                                </Select>
                            </FormControl>




                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Fragment>
                            <h2>Education</h2>
                            {education.length > 0 && isEditing ? (
                                education.map((item, index) => (
                                    <EducationItem
                                        key={index}
                                        id={item._id}
                                        index={index}
                                        details={getEducationDetails(index)}
                                        onUpdate={handleEditEducation}

                                    />
                                ))
                            ) : (
                                <>
                                    <p>Add Education Information</p>
                                    <EducationForm onAdd={handleAddEducation} />
                                </>
                            )}

                        </Fragment>

                    )}
                    {activeStep === 2 && (
                        <Fragment>
                            <h2>Work Experience</h2>
                            {experience.length > 0 && isEditing ? (
                                experience.map((item, index) => (
                                    <ExperienceItem
                                        key={index}
                                        index={index}
                                        details={getExperienceDetails(index)}
                                        onUpdate={handleEditExperience}
                                    />
                                ))
                            ) : (
                                <>
                                    <p>Add Work Experience</p>
                                    <ExperienceForm onAdd={handleAddExperience} />
                                </>
                            )}

                        </Fragment>
                    )}
                    {activeStep === 3 && (
                        <div>
                            {/* <FormControl sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="demo-multiple-chip-label">Skills</InputLabel>
                                <Select
                                    labelId="demo-multiple-skill-label"
                                    id="demo-multiple-skill"
                                    multiple
                                    value={userSkill}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Skills" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {professionalSkills.map((skill) => (
                                        <MenuItem
                                            key={skill}
                                            value={skill}
                                            style={getStyles(skill, userSkill, theme)}
                                        >
                                            {skill}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}

                            <Autocomplete
                                multiple
                                options={professionalSkills}
                                value={userSkill}
                                onChange={handleChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Skill"
                                        placeholder="Start typing..."
                                    />
                                )}
                                renderTags={(selected, getTagProps) =>
                                    selected.map((value, index) => (
                                        <Chip key={value} label={value} {...getTagProps({ index })} />
                                    ))
                                }
                                filterSelectedOptions
                                sx={{ mt: 2, width: "100%" }}
                            />

                            <Typography textAlign="center" mt={2}>
                                <Button onClick={handleAddSkill} variant="contained" className="button-primary">Add Skill(s)</Button>
                            </Typography>
                        </div>

                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 5 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            variant="text"
                            startIcon={<KeyboardBackspaceIcon />}

                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}
                        <Button onClick={activeStep === steps.length - 1 ? handleSubmit :
                            activeStep === 0 ? handleStepOne : activeStep === 1 ? handleStepTwo :
                                activeStep === 2 && handleStepThree
                        }
                            variant="contained"
                            endIcon={<ChevronRightIcon />}
                            size="small"
                            className="button-primary"
                        >
                            {activeStep === steps.length - 1 ? 'Save Profile' : 'Continue'}
                        </Button>
                    </Box>
                </Fragment>
            )}
        </Box>
    );
}
