import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { experienceDetailsReset, getExperienceDetails, getUserDetails, setItem } from '../../../actions/userActions';
import { sanitizeInput, states } from '../../../utils/Validate';

const ExperienceItem = () => {

    const { experience, loading: loadingExperience } = useSelector((state) => state.experienceDetails);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [title, setTitle] = useState(experience?.title || '');
    const [organization, setOrganization] = useState(experience?.organization || '');
    const [location, setLocation] = useState(experience?.location || '');
    const [date_start, setExpStart] = useState(experience?.date_start || dayjs());
    const [date_end, setExpEnd] = useState(experience?.date_end || dayjs());
    const [currentlyWorkHere, setCurrentlyWorkHere] = useState(experience?.currentlyWorkHere || false);
    const [responsibilities, setResponsibilities] = useState(experience?.responsibilities || '');
    const [loading, setLoading] = useState(false)

    const { experienceId } = useParams()
    const { userInfo } = useSelector(state => state.userLogin)


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const updatedExperience = { title, organization, location, date_start, date_end, currentlyWorkHere, responsibilities };

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.put(`/api/users/experience/${experienceId}`, { updatedExperience }, config);
            if (res.data.success) {
                setLoading(false)
                dispatch(experienceDetailsReset())
                dispatch(getUserDetails("profile"))

                setTimeout(() => {
                    dispatch(setItem("profile"))
                    navigate('/profile');
                }, 3000);
                return toast('Experience Updated Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.data) {

                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    useEffect(() => {


        if (!experience || !experience.title) {
            dispatch(getExperienceDetails(experienceId))
        } else {
            setTitle(experience.title)
            setOrganization(experience.organization)
            setLocation(experience.location)
            setExpStart(experience.date_start)
            setExpEnd(experience.date_end)
            setCurrentlyWorkHere(experience.currentlyWorkHere)
            setResponsibilities(experience.responsibilities)
        }


    }, [dispatch, experienceId, experience])

    return (

        <Fragment>
            <ToastContainer />

            {loadingExperience ? <Typography textAlign="center"><CircularProgress /> </Typography> : (
                <Grid container mt={1}>

                    <Grid md={3} xs={12}></Grid>
                    <Grid md={6} xs={12} sx={{
                        py: 2,
                        px: 3, mt: 3,
                        borderRadius: "10px",
                        border: "1px solid #eaeaea",
                    }}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2 }}

                                label="Job Title"

                                value={sanitizeInput(title)}
                                onChange={(e) => setTitle(e.target.value)}


                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2 }}
                                label="Organization"


                                value={sanitizeInput(organization)}
                                onChange={(e) => setOrganization(e.target.value)}


                            />

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel >Location</InputLabel>
                                <Select
                                    labelId="location"

                                    value={location}
                                    label="Location"
                                    required
                                    onChange={(e) => setLocation(e.target.value)}
                                >

                                    {states.map(state => (
                                        <MenuItem value={state} key={state}>{state}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>


                            <div style={{ marginBottom: "20px" }}  >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            label="Start Date"
                                            name="date_start"
                                            sx={{ width: "100%", }}
                                            value={dayjs(date_start)}
                                            onChange={(newValue) => setExpStart(newValue)}


                                        />

                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div  >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>

                                        <DatePicker
                                            label="End Date"
                                            name="date_end"
                                            sx={{ width: "100%", }}
                                            value={dayjs(date_end)}
                                            onChange={(newValue) => setExpEnd(newValue)}

                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>


                            <FormGroup sx={{ mt: 2 }}>

                                <FormControlLabel required control={<Checkbox
                                    checked={currentlyWorkHere}

                                    onChange={() => setCurrentlyWorkHere(!currentlyWorkHere)}
                                />} label="I currently work here" />

                                <TextField
                                    onChange={(e) => setResponsibilities(e.target.value)}
                                    label="Job Responsibilities"
                                    multiline
                                    value={sanitizeInput(responsibilities)}

                                    rows={4}
                                    placeholder="Outline your Achievements in this Role"
                                    sx={{ mt: 2 }}
                                />


                            </FormGroup>


                            {/* Similar input fields and logic for field, qualification, eduStart, eduEnd */}
                            <Typography textAlign="center" mt={5}>
                                <LoadingButton loading={loading} type="submit" variant="contained" className="button-primary">Update Experience</LoadingButton>
                            </Typography>
                        </form>
                    </Grid>
                    <Grid xs={3}></Grid>
                </Grid>

            )}

        </Fragment>



    );
};

export default ExperienceItem